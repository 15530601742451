<template>
  <div class="wrapper" v-html="mdText">
  </div>
</template>

<script setup>
import { isZh } from 'app/i18n'
import md from '@/assets/md/zh/api/list/submittask.md'
import en_md from '@/assets/md/en/api/list/submittask.md'
const mdText = isZh() ? md : en_md
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
